const sharedConfig = require("../config/sharedConfig");

const { requestorClientId, requestorPassword } = sharedConfig;

const DEFAULT_FILESERVER_DOMAIN_URL = requestorClientId;

function getRequestor(hostname) {
  return {
    ClientId: getClientId(hostname),
    Password: requestorPassword,
  };
}

function getClientId(hostname) {
  return requestorClientId && requestorClientId.length > 0
    ? requestorClientId
    : getClientIdFromHostname(hostname);
}

function getClientIdFromHostname(hostname) {
  return hostname.split(":")[0];
}

function getDomainForFileServer(domain) {
  if (domain.includes("localhost") || domain.includes("d4:")) {
    return DEFAULT_FILESERVER_DOMAIN_URL;
  }
  // removes port from url on gol.
  if (domain.includes(".in.") || domain.startsWith("gitlab")) {
    return domain.split(":")[0];
  }

  return domain;
}

module.exports = {
  getClientId,
  getRequestor,
  getDomainForFileServer,
  DEFAULT_FILESERVER_DOMAIN_URL,
};
