import React, { ReactChild } from "react";

type AlertType =
  | "success"
  | "warning"
  | "warningWithBlackText"
  | "error"
  | "info";

interface AlertProps {
  children: ReactChild;
  type: AlertType;
}

export default function Alert(props: AlertProps) {
  return <div className={`alert alert-${props.type}`}>{props.children}</div>;
}
