export const formatCityLabel = (label) => {
  if (!label) {
    return "";
  }

  let newLabel = "";

  const cities = label.split("/");
  cities.forEach((city, index) => {
    const cityName = city.split("-");
    if (cities.length > 0 && index !== cities.length - 1) {
      newLabel += `${cityName[0]}/`;
    } else if (cities.length > 0 && index === cities.length - 1) {
      newLabel += cityName[0];
    } else {
      newLabel = label;
    }
  });

  return newLabel;
};
