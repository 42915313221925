import cancelCustomerToken from "../gol-api/cancelCustomerToken";
import getClientToken from "../gol-api/clientToken";
import { isMobile } from "../lib/isMobile";
import {
  COOKIE_BACKEND_ID_NAME,
  COOKIE_CBT_TOKEN_NAME,
  COOKIE_CUSTOMER_DEVICE,
  COOKIE_CUSTOMER_NAME_NAME,
  COOKIE_CUSTOMER_TOKEN_NAME,
  COOKIE_PASSIVE_SESSION_ID_NAME,
  COOKIE_REQUESTOR_PUBLIC_KEY,
} from "./cookies";
import { deleteCookie, getCookie, setCookie } from "./cookiesOps";

const ANONYMOUS_CUSTOMER_TOKEN_VALIDITY_MS = 20 * 60 * 1000; // 20 min

export function setCustomerToken(customerToken: string, isAnonymous = false) {
  return setCookie({
    name: COOKIE_CUSTOMER_TOKEN_NAME,
    value: customerToken,
    expiration: isAnonymous && ANONYMOUS_CUSTOMER_TOKEN_VALIDITY_MS,
  });
}

export function setCustomerDevice(device) {
  if (!device.id || !device.name) {
    return false;
  }

  return setCookie({
    name: COOKIE_CUSTOMER_DEVICE,
    value: JSON.stringify(device),
  });
}

export function getCustomerDevice(): { id: string; name: string } {
  const device =
    getCookie(COOKIE_CUSTOMER_DEVICE) || JSON.stringify({ id: "", name: "" });
  return JSON.parse(device);
}

export function deleteCustomerDevice() {
  deleteCookie(COOKIE_CUSTOMER_DEVICE);
}

export function getCustomerToken() {
  return getCookie(COOKIE_CUSTOMER_TOKEN_NAME);
}

export function deleteCustomerToken() {
  deleteCookie(COOKIE_CUSTOMER_TOKEN_NAME);
}

export function setCbtToken(cbtToken: string) {
  if (!cbtToken) {
    return;
  }

  return setCookie({
    name: COOKIE_CBT_TOKEN_NAME,
    value: cbtToken,
  });
}

export function getCbtToken(frontendSettings?: any) {
  const cbtToken = getCookie(COOKIE_CBT_TOKEN_NAME);

  if (!cbtToken) {
    return;
  }

  if (!frontendSettings && cbtToken) {
    return cbtToken;
  }

  return (
    frontendSettings?.dealerCorporateSettings?.enableCbt === "true" && cbtToken
  );
}

export function deleteCbtToken() {
  deleteCookie(COOKIE_CBT_TOKEN_NAME);
}

export function setCustomerName(customerName: string) {
  setCookie({ name: COOKIE_CUSTOMER_NAME_NAME, value: customerName });
}

export function getCustomerName() {
  return getCookie(COOKIE_CUSTOMER_NAME_NAME);
}

export function deleteCustomerName() {
  deleteCookie(COOKIE_CUSTOMER_NAME_NAME);
}

export function getPassiveSessionId() {
  const passiveSessionIdFromCookie = getCookie(COOKIE_PASSIVE_SESSION_ID_NAME);
  if (passiveSessionIdFromCookie) {
    return passiveSessionIdFromCookie;
  }

  const generatedD4passiveSessionId = `1${generateRandomNumber(
    10000000,
    99999999
  )}`;

  setCookie({
    name: COOKIE_PASSIVE_SESSION_ID_NAME,
    value: generatedD4passiveSessionId,
  });

  return generatedD4passiveSessionId;
}

export async function logoutCustomer() {
  const device = await getCustomerDevice();
  await cancelCustomerToken(isMobile() ? device.id : null);

  deleteCustomerToken();
  deleteCbtToken(); // cbt future,
  deleteCustomerName();
  deleteRequestorPublicKey();
  deleteCustomerDevice();

  if (process.browser) {
    sessionStorage.removeItem(COOKIE_REQUESTOR_PUBLIC_KEY);
  }

  // current cbt
  deleteCookie("token");
  deleteCookie("username");
  deleteCookie("cbtToken");
}

export async function assureCorrectCustomerToken({
  rewrite = false,
}: {
  rewrite?: boolean;
}): Promise<boolean> {
  if (getCustomerToken() && !rewrite) {
    return true;
  }

  const deviceId = generateRandomNumber(1000, 999999999);
  const deviceName =
    (window.navigator && window.navigator.userAgent) ||
    generateRandomNumber(1000, 999999999);

  const customerTokenRaw = await getClientToken({ deviceId, deviceName });
  const anonymousCustomerToken =
    customerTokenRaw.ResponseDetail.CreateCustomerTokenResponse_1.Token.$t;

  setCustomerDevice({ id: deviceId.toString(), name: deviceName });
  setCustomerToken(anonymousCustomerToken, true);
  return true;
}

// TODO: settle in a better place
export function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getBackendID() {
  if (process.browser) {
    if (window.location.search.includes("backend_id=")) {
      return window.location.search.split("backend_id=")[1];
    }
  }
  return getCookie(COOKIE_BACKEND_ID_NAME);
}

export function setRequestorPublicKey(requestorPublicKey: string) {
  return setCookie({
    name: COOKIE_REQUESTOR_PUBLIC_KEY,
    value: requestorPublicKey,
  });
}

export function getRequestorPublicKey() {
  return getCookie(COOKIE_REQUESTOR_PUBLIC_KEY);
}

export function deleteRequestorPublicKey() {
  deleteCookie(COOKIE_REQUESTOR_PUBLIC_KEY);
}
