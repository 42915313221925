import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

function getGolRequestCancelCustomerTokenQuery(customerDeviceId: string) {
  return {
    GolApi: {
      RequestDetail: {
        CancelCustomerTokenRequest_1: customerDeviceId
          ? {
              CancelCustomerDevice: {
                Id: customerDeviceId.toString(),
              },
            }
          : {},
      },
    },
  };
}

export default async (customerDeviceId: string) => {
  const golRequest = getGolRequestCancelCustomerTokenQuery(customerDeviceId);
  const cancelCustomerResponse = await golApiCall(golRequest);
  return createResponse(cancelCustomerResponse);
};
