import * as actionTypes from "../actions/actionTypes";

const initState = {
  shouldMinimizeSearchForm: true,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOULD_MINIMIZE_SEARCH_FORM:
      return { ...state, shouldMinimizeSearchForm: action.data };

    default:
      return state;
  }
};

export default reducer;
