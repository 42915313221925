import { createResponse } from "../lib/functions";
import golApiCall from "./golApiCall";

const golRequest = () => ({
  GolApi: {
    RequestDetail: {
      DetailCustomerRequest_2: {},
    },
  },
});

export default async (options) => {
  const customerTokenOption = {
    customerToken: options?.customerToken ? options.customerToken : null,
  };
  const requestorPublicKeyOption = options?.requestorPublicKey
    ? { requestorPublicKey: options.requestorPublicKey }
    : null;
  const clientId = options?.clientId || undefined;

  const golApiCallOptions = {
    ...customerTokenOption,
    ...requestorPublicKeyOption,
    clientId,
    golApiUrl: options?.golApiUrl,
  };

  const response = await golApiCall(golRequest(), golApiCallOptions);

  const responseObj = createResponse(response);

  if (responseObj.success) {
    responseObj.data = {
      ...responseObj.data,
      formattedData: formatResponse(responseObj.data),
    };

    return responseObj;
  }

  return responseObj;
};

const formatResponse = ({
  CustomerInfo,
  CustomerPassengers,
  CustomerSettings,
  RequestorPrivateSettings,
}) => {
  const customerDetail = CustomerInfo.Person;

  const cbtToken = RequestorPrivateSettings?.TokenCBT.$t;
  const passengers = CustomerPassengers?.CustomerPassenger || [];

  const normalizePerson = (person) => ({
    email: person.Email.$t,
    givenName: person.GivenName.$t,
    firstname: person.GivenName.$t,
    surname: person.Surname.$t,
    telephone: person.Telephone.PhoneNumber,
    namePrefix: person.NamePrefix,
    birthDate: person.BirthDate,
  });

  return {
    username: CustomerInfo.Username,
    ...normalizePerson(customerDetail),
    passports: CustomerSettings.Passports.Passport.map((pass) => ({
      ...pass,
      passportNumber: pass.Number,
    })).filter((passport) => passport.Number.trim() !== ""),
    shippingInfo: {},
    billingInfo: CustomerInfo.BillingInfo && {
      company: CustomerInfo.BillingInfo?.Company?.$t,
      ic: CustomerInfo.BillingInfo?.Company?.Ic,
      dic: CustomerInfo.BillingInfo?.Company?.Dic,
      street: CustomerInfo.BillingInfo?.Street?.$t,
      city: CustomerInfo.BillingInfo?.CityName?.$t,
      postalCode: CustomerInfo.BillingInfo?.CityName?.Zipcode,
      country: CustomerInfo.BillingInfo?.CityName?.Country,
    },
    loyalties: [...CustomerSettings.Loyalties.LoyaltyProgram],
    passengers: passengers.map((passenger) => ({
      id: passenger.PassengerId,
      ...normalizePerson(passenger.Person),
    })),
    newsletter: CustomerSettings.Newsletter.Active === "true",
    specialOffers: CustomerSettings.SpecialOffers.Active === "true",
    cbtToken,
  };
};
