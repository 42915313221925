import moment from "moment/moment";
import React, { FC } from "react";
import { IntlShape, defineMessages } from "react-intl";

import { dayMonthFormat } from "shared/lib/datetime";
import { countPassengers } from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import { formatCityLabel } from "@components/SearchForm/formatCityLabel";
import FormattedMessage from "@components/UI/FormattedMessage";

type MinimizedHeaderSearchFormProps = {
  from: {
    label: string;
    value: string;
  };
  to: {
    label: string;
    value: string;
  };
  departureDate: string;
  returnDate?: string;
  currentLanguage: string;
  defaultCountry: string;
  passengers: PassengersCount;
  flightClass: string;
  intl?: IntlShape;
  onButtonClick: () => void;
  typeSearch: string;
};

const {
  PropertiesSelectClassEconomy,
  PropertiesSelectClassPremiumEconomy,
  PropertiesSelectClassBusiness,
  PropertiesSelectClassFirstClass,
} = defineMessages({
  PropertiesSelectOnePassenger: formattedMessageParams(
    "PropertiesSelect.OnePassenger"
  ),
  PropertiesSelectTwoThreePassengers: formattedMessageParams(
    "PropertiesSelect.TwoThreePassengers"
  ),
  PropertiesSelectFourPlusPassengers: formattedMessageParams(
    "PropertiesSelect.FourPlusPassengers"
  ),
  PropertiesSelectClassEconomy: formattedMessageParams(
    "SearchForm.classEconomy"
  ),
  PropertiesSelectClassPremiumEconomy: formattedMessageParams(
    "SearchForm.classPremiumEconomy"
  ),
  PropertiesSelectClassBusiness: formattedMessageParams(
    "SearchForm.classBusiness"
  ),
  PropertiesSelectClassFirstClass: formattedMessageParams(
    "SearchForm.classFirstClass"
  ),
});

const MinimizedHeaderSearchForm: FC<MinimizedHeaderSearchFormProps> = ({
  from,
  to,
  departureDate,
  currentLanguage,
  defaultCountry,
  returnDate,
  passengers,
  flightClass,
  intl,
  onButtonClick,
  typeSearch,
}) => {
  return (
    <div className="minimized-header-search-desktop">
      <div style={{ overflow: "hidden", height: "40px" }}>
        <div
          style={{
            float: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "80%",
            height: "40px",
            paddingTop: "6px",
          }}
        >
          <LocationsPart from={from} to={to} typeSearch={typeSearch} />
        </div>
        <div
          style={{
            float: "right",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "40px",
          }}
        >
          <button
            className="button-main-change"
            type="button"
            onClick={onButtonClick}
          >
            <FormattedMessage id="SearchForm.change" />
          </button>
        </div>
        <div
          style={{
            float: "left",
            paddingLeft: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "40px",
            paddingTop: "6px",
          }}
        >
          <DatesPart
            departureDate={departureDate}
            returnDate={returnDate}
            defaultCountry={defaultCountry}
            currentLanguage={currentLanguage}
            typeSearch={typeSearch}
          />
        </div>
        <div
          style={{
            float: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "40px",
            paddingTop: "6px",
            paddingLeft: "16px",
          }}
        >
          <OptionsPart
            flightClass={flightClass}
            passengers={passengers}
            intl={intl}
          />
        </div>
      </div>
    </div>
  );
};

const LocationsPart: FC<{
  from: {
    label: string;
    value: string;
  };
  to: {
    label: string;
    value: string;
  };
  typeSearch: string;
}> = ({ from, to, typeSearch }) => (
  <>
    <TypeSearchIcon typeSearch={typeSearch} />{" "}
    <span role="button" id="from-value" style={{ verticalAlign: "middle" }}>
      <span className="header-search-form-inner-field-value">
        {formatCityLabel(from.label)}
      </span>{" "}
      <span className="header-search-form-inner-field-additional">
        {" "}
        ({from.value})
      </span>
      <Img
        src="/static/images/ico-separator.svg"
        alt="separator icon"
        style={{
          marginRight: "5px",
          marginLeft: "5px",
        }}
      />
      <span className="header-search-form-inner-field-value">
        {formatCityLabel(to.label)}
      </span>{" "}
      <span className="header-search-form-inner-field-additional">
        {" "}
        ({to.value})
      </span>
    </span>
  </>
);

const DatesPart: FC<{
  departureDate: string;
  returnDate?: string;
  currentLanguage: string;
  defaultCountry: string;
  typeSearch: string;
}> = ({
  departureDate,
  returnDate,
  currentLanguage,
  defaultCountry,
  typeSearch,
}) => {
  const weekday = (date: string) =>
    moment(new Date(`${date} 12:00`))
      .locale(currentLanguage)
      .format("dd")
      .toUpperCase();
  if (typeSearch === "ONE_WAY") {
    return (
      <span role="button">
        <span className="header-search-form-inner-field-value">
          {weekday(departureDate)}
          {", "}
        </span>
        <span className="header-search-form-inner-field-additional">
          {moment(departureDate).format(dayMonthFormat(defaultCountry))}
        </span>
      </span>
    );
  }
  return (
    <span>
      <span role="button">
        <span className="header-search-form-inner-field-value">
          {weekday(departureDate)}
          {", "}
        </span>
        <span className="header-search-form-inner-field-additional">
          {moment(departureDate).format(dayMonthFormat(defaultCountry))}
        </span>
      </span>
      <Img
        src="/static/images/ico-separator.svg"
        alt="separator icon"
        style={{
          marginRight: "5px",
          marginLeft: "5px",
        }}
      />
      <span role="button">
        <span className="header-search-form-inner-field-value">
          {weekday(returnDate)}
          {", "}
        </span>
        <span className="header-search-form-inner-field-additional">
          {moment(returnDate).format(dayMonthFormat(defaultCountry))}
        </span>
      </span>
    </span>
  );
};

const OptionsPart: FC<{
  passengers: PassengersCount;
  flightClass: string;
  intl?: IntlShape;
}> = ({ passengers, flightClass, intl }) => {
  const flightClassTranslation = {
    ECO: {
      label: intl.formatMessage(PropertiesSelectClassEconomy).toUpperCase(),
    },
    PRE: {
      label: intl
        .formatMessage(PropertiesSelectClassPremiumEconomy)
        .toUpperCase(),
    },
    BUS: {
      label: intl.formatMessage(PropertiesSelectClassBusiness).toUpperCase(),
    },
    "1ST": {
      label: intl.formatMessage(PropertiesSelectClassFirstClass).toUpperCase(),
    },
  };
  return (
    <span>
      <Img
        src="/static/images/ico-person.svg"
        alt="person icon"
        style={{
          marginRight: "5px",
        }}
      />
      <span role="button">
        <span className="header-search-form-inner-field-value">
          {countPassengers(passengers)}
          {", "}
        </span>
        <span className="header-search-form-inner-field-additional">
          {flightClassTranslation[flightClass]?.label.toLowerCase()}
        </span>
      </span>
    </span>
  );
};

const TypeSearchIcon: FC<{ typeSearch: string }> = ({ typeSearch }) => {
  const typeSearchIconFilename = {
    ONE_WAY: "ico-plane-oneway-small.svg",
    RETURN: "ico-plane-back-small.svg",
    MULTIPLE: "ico-plane-double-small.svg",
  };

  const additionalJump = typeSearch === "RETURN" ? { marginBottom: "5px" } : {};

  return (
    <Img
      src={`/static/images/${typeSearchIconFilename[typeSearch]}`}
      alt="type search icon"
      style={{
        marginRight: "5px",
        verticalAlign: "middle",
        ...additionalJump,
      }}
    />
  );
};

export default MinimizedHeaderSearchForm;
