import * as React from "react";

import Footer from "@components/UI/Footer";
import Header from "@components/UI/Header/Header";
import Loader from "@components/UI/Loader";
import RouteChangeListener from "@components/UI/RouteChangeListener";

interface IStandardLayout {
  route?: string;
  hideSearchForm?: boolean;
  showCondensedHeaderLogoStripe?: boolean;
  hideFooter?: boolean;
  nonce?: string;
}

const StandardLayout: React.FC<IStandardLayout> = ({
  hideFooter,
  route,
  showCondensedHeaderLogoStripe,
  hideSearchForm,
  children,
  nonce,
}) => {
  return (
    <>
      <div className="layout layout--standard">
        <div className="main-wrapper">
          <Header
            showCondensedHeaderLogoStripe={showCondensedHeaderLogoStripe}
            hideSearchForm={hideSearchForm}
          />
          <RouteChangeListener />
        </div>
        {children}
        <Loader />
      </div>
      {hideFooter !== true && <Footer route={route} nonce={nonce} />}
    </>
  );
};

export default StandardLayout;
