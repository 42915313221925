import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { useCostCenters } from "shared/cbt/hooks";
import {
  addTraveller,
  changeCostCenter,
  changeFlightClass,
  changeTravelReason,
  decrementGuests,
  incrementGuests,
  removeTraveller,
} from "shared/data/actions/cbt";
import { AppState } from "shared/data/reducers";
import { countPassengers } from "shared/lib/functions";
import { cutString } from "shared/lib/stringFormat";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import NumberStepper from "@components/Forms/Inputs/NumberStepper";
import Counter from "@components/SearchForm/Counter";
import ReusableSelect from "@components/SearchForm/ReusableSelect";
import TravellersSelect from "@components/SearchForm/TravellersSelect";
import FormattedMessage from "@components/UI/FormattedMessage";
import SimpleSelect from "@components/UI/SimpleSelect";

import { checkIsHotelSearchForm } from "@lib/webFunctions";

const { pleaseSelect, removeTravellerText } = defineMessages({
  pleaseSelect: formattedMessageParams("General.pleaseSelect"),
  pleaseType: formattedMessageParams("General.pleaseType"),
  removeTravellerText: formattedMessageParams("General.removeTraveler"),
});

export function PassengersOptionsDialog({
  onClose,
  passengersForm,
  users,
  costCenters,
  travelReasons,
  corporate,
  flightClasses,
  cbtToken,
  passengers,
  plusCounterHandler,
  minusCounterHandler,
}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    travellers: travellersOrigin,
    flightClass,
    costCenter,
    travelReason,
    guests,
    errorMaxGuests,
  } = passengersForm;
  const travellers = travellersOrigin.flatMap((t) => (t ? [t] : []));
  const selectedTravellersIds = new Set(travellers);
  const [addPersonOpened, setAddPersonOpened] = useState<boolean>(false);
  const [costCenterModalOpen, setCostCenterModalOpen] = useState<{
    open: boolean;
    choosen: string;
  }>({ open: false, choosen: "" });

  const addPersonSelectRef = useRef<HTMLDivElement>(null);
  const costCenterModalOpenRef = useRef<HTMLDivElement>(null);
  const numAllGuests = countPassengers(passengers) + travellers.length;

  const {
    customerUsername,
    currentTravellerIds,
    allowedTravelerTypes,
    grades,
  } = useSelector((state: AppState) => ({
    customerUsername: state.user.email,
    currentTravellerIds: state.cbt.passengersForm.travellers,
    allowedTravelerTypes: state.requestorConfig.allowedTravelerTypes,
    grades: state.cbt.grades,
  }));

  const { selectedCostCenter, allowedCostCenters } = useCostCenters({
    corporate,
    costCenter,
    costCenters,
    travellers,
    users,
    dispatch,
  });

  useLayoutEffect(() => {
    const el = document.querySelector(".passengers-options-dialog");
    const close = (e) => {
      if (!addPersonSelectRef.current?.contains(e.target)) {
        setAddPersonOpened(false);
      }
      if (!costCenterModalOpenRef.current?.contains(e.target)) {
        setCostCenterModalOpen({
          choosen: selectedCostCenter
            ? selectedCostCenter.label
            : intl.formatMessage(pleaseSelect),
          open: false,
        });
      }
    };
    el?.addEventListener("click", close);
    return () => el?.removeEventListener("click", close);
  }, [selectedCostCenter, intl]);

  useEffect(() => {
    // Only one searcher can be openned
    if (costCenterModalOpen.open) {
      if (addPersonOpened) {
        setCostCenterModalOpen({ ...costCenterModalOpen, open: false });
      }
    }
  }, [
    addPersonOpened,
    costCenterModalOpen,
    cbtToken,
    currentTravellerIds,
    customerUsername,
    dispatch,
    users,
    corporate,
  ]);

  const router: any = useRouter();
  const isHotelSearchForm = checkIsHotelSearchForm(router);

  const canAddGuests =
    corporate?.AllowGuests && !corporate?.TravelArranger?.SoloTraveller;

  const availableGuestsPassengerTypeCodes = grades || [];

  const allowedGuests = availableGuestsPassengerTypeCodes
    .filter((guestItem) =>
      allowedTravelerTypes.some(
        (item) =>
          item.Code === guestItem.PassengerTypeCode && guestItem.EnableForGuests
      )
    )
    .map((guestItem) => {
      const matchingItem = allowedTravelerTypes.find(
        (item) =>
          item.Code === guestItem.PassengerTypeCode && guestItem.EnableForGuests
      );
      return matchingItem ? { ...guestItem, ...matchingItem } : null;
    })
    .filter(Boolean);

  const maxNumPassengers = 9;
  const isMaxPassengersSelected = numAllGuests >= maxNumPassengers;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className="loader-wrapper-mini" onClick={onClose} />
      <div className={classNames("passengers-options-dialog")}>
        <div className="passengers-options-dialog__row">
          <div>
            <FormattedMessage id="General.travellers" />
          </div>
          <div
            className="relative passengers-options-dialog__container"
            ref={addPersonSelectRef}
          >
            <button
              type="button"
              id="cbt-add-traveler"
              className="passengers-options-dialog__add-person-btn passenger-options-button-arrow-icon-indentation"
              onClick={() => {
                setAddPersonOpened((opened) => !opened);
              }}
            >
              <FormattedMessage id="General.addPerson" />
              <Img
                src="/static/images/ico-arrow-down.svg"
                alt="down arrow icon"
                className={classNames(
                  {
                    "select-arrow-rotate-up": addPersonOpened,
                  },
                  "passenger-options-button-text-icon-indentation"
                )}
              />
            </button>
            {addPersonOpened && (
              <div className="">
                <TravellersSelect
                  className="passengers-options-dialog__travelers-select"
                  selectedTravellersIds={selectedTravellersIds}
                  onChange={({ value }) => dispatch(addTraveller(value))}
                  tooltip={(option) => option.Email}
                  close={() => setAddPersonOpened(false)}
                  cbtToken={cbtToken}
                />
              </div>
            )}
          </div>
        </div>
        <div className="passengers-options-dialog__travelers">
          {travellers.map(
            (value) =>
              value && (
                <div
                  key={value}
                  className="passengers-options-dialog__traveler"
                >
                  {users[value]?.label}
                  <button
                    className="passengers-options-dialog__travelers-remove-btn"
                    type="button"
                    onClick={() => dispatch(removeTraveller(value))}
                    title={intl.formatMessage(removeTravellerText)}
                  >
                    {intl.formatMessage(removeTravellerText)}
                  </button>
                </div>
              )
          )}
        </div>
        {canAddGuests && (
          <>
            {isHotelSearchForm ? (
              <>
                <div className="passengers-options-dialog__row passenger-options-button-arrow-icon-indentation">
                  <div>
                    <FormattedMessage id="General.guests" />
                  </div>
                  <NumberStepper
                    value={guests}
                    disableDecrease={guests === 0}
                    increase={() => dispatch(incrementGuests())}
                    decrease={() => dispatch(decrementGuests())}
                  />
                </div>
              </>
            ) : (
              <>
                {allowedGuests.map((allowedTravelerType) => (
                  <tr
                    key={allowedTravelerType.Code}
                    className="passengers-options-dialog__row passenger-options-button-arrow-icon-indentation"
                  >
                    <td>
                      <span>{allowedTravelerType.Name}</span>
                    </td>
                    <td>
                      <Counter
                        type={`${allowedTravelerType.Id}_${allowedTravelerType.Code}`}
                        disabledMinus={
                          passengers[
                            `${allowedTravelerType.Id}_${allowedTravelerType.Code}`
                          ] === 0
                        }
                        disabledPlus={isMaxPassengersSelected}
                        value={
                          passengers[
                            `${allowedTravelerType.Id}_${allowedTravelerType.Code}`
                          ]
                        }
                        onPlusSign={(type) =>
                          plusCounterHandler(type, allowedTravelerType.Id)
                        }
                        onMinusSign={(type) =>
                          minusCounterHandler(type, allowedTravelerType.Id)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </>
        )}
        {!isHotelSearchForm && (
          <div className="passengers-options-dialog__row passengers-options-dialog__row--borders">
            <div>
              <FormattedMessage id="Flight.flightClass" />
            </div>
            <div>
              <SimpleSelect
                right
                id="cbt-flight-class"
                options={flightClasses}
                onChange={({ value }) => {
                  dispatch(changeFlightClass(value));
                }}
                isSearchable={false}
                value={flightClasses.find(({ value }) => value === flightClass)}
                components={{
                  SingleValue(props) {
                    return (
                      <div className="passengers-options-dialog__selected-value">
                        {props.data.label}
                      </div>
                    );
                  },
                }}
              />
            </div>
          </div>
        )}
        <div className="passengers-options-dialog__row">
          <div>
            <FormattedMessage id="General.costCentre" />
          </div>
          <div
            className="relative passengers-options-dialog__container"
            ref={costCenterModalOpenRef}
          >
            <button
              type="button"
              id="cbt-cost-center"
              className={`passengers-options-dialog__add-person-btn cost-center-font passenger-options-button-arrow-icon-indentation ${
                selectedCostCenter ? "cost-center-selected" : ""
              }`}
              onClick={() =>
                setCostCenterModalOpen({
                  ...costCenterModalOpen,
                  open: !costCenterModalOpen.open,
                })
              }
            >
              <FormattedMessage
                id={
                  selectedCostCenter
                    ? cutString(selectedCostCenter.label, 30)
                    : "BrandedFares.ModalTable.SelectBrand"
                }
              />
              <Img
                src="/static/images/ico-arrow-down.svg"
                alt="down arrow icon"
                className={classNames(
                  {
                    "select-arrow-rotate-up": costCenterModalOpen.open,
                  },
                  "passenger-options-button-text-icon-indentation"
                )}
              />
            </button>

            {costCenterModalOpen.open && (
              <ReusableSelect
                optionsArray={allowedCostCenters}
                onChange={({ label, value }) => {
                  dispatch(changeCostCenter(value));
                  setCostCenterModalOpen({
                    open: false,
                    choosen: label,
                  });
                }}
              />
            )}
          </div>
        </div>
        <div className="passengers-options-dialog__row">
          <div>
            <FormattedMessage id="General.travelReason" />
          </div>
          <div>
            <SimpleSelect
              right
              id="cbt-travel-reason"
              placeholder={intl.formatMessage({
                id: "BrandedFares.ModalTable.SelectBrand",
              })}
              className="passengers-options-dialog__select"
              options={travelReasons}
              onChange={({ value }) => {
                dispatch(changeTravelReason(value));
              }}
              isSearchable={false}
              value={travelReasons.find(({ value }) => value === travelReason)}
              components={{
                SingleValue(props) {
                  return (
                    <div
                      style={
                        props.data.label ? { textTransform: "uppercase" } : {}
                      }
                      className="passengers-options-dialog__selected-value"
                    >
                      {cutString(props.data.label, 28)}
                    </div>
                  );
                },
              }}
            />
          </div>
        </div>
        <div className="passengers-options-dialog__confirm-row">
          {errorMaxGuests && (
            <div className="passengers-options-dialog__error-msg">
              <FormattedMessage id="PropertiesSelect.maxPassengersError" />
            </div>
          )}
          <button
            type="button"
            className="button-secondary-color passengers-options-dialog__confirm"
            onClick={onClose}
          >
            <FormattedMessage id="PropertiesSelect.done" />
          </button>
        </div>
      </div>
    </>
  );
}
