import * as actionTypes from "./actionTypes";

export const setShouldMinimizeSearchForm = (
  shouldMinimizeSearchForm: boolean
) => {
  return {
    type: actionTypes.SET_SHOULD_MINIMIZE_SEARCH_FORM,
    data: shouldMinimizeSearchForm,
  };
};
