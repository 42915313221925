import { useRouter } from "next/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setShouldMinimizeSearchForm } from "shared/data/actions/headerSearchForm";

function RouteChangeListener() {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const minimizeSearchForm = async () => {
      await dispatch(setShouldMinimizeSearchForm(true));
    };

    minimizeSearchForm();
  }, [dispatch, router.pathname]);

  return <></>;
}

export default RouteChangeListener;
