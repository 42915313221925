import { CbtPassengersForm } from "shared/data/reducers/cbtReducer";
import { ExtendedResponse2 } from "shared/lib/golObjectTypes/SearchFlightResponse/ExtendedResponse2";

import { getTripType, resolvePoliciesGOL } from "../cbt/api/policies";
import { SearchQuery } from "../data/reducers/types/storage";
import {
  createResponse,
  parseAirplanes,
  parseAirport,
  parseTransportCompanies,
  safeUpdate,
} from "../lib/functions";
import {
  FlightStep,
  SearchFlightsRequestExt,
  SearchedPassenger,
} from "../lib/golObjectTypes/SearchFlightResponse/ExtendedRequest";
import { isFalsy } from "../lib/isFalsy";
import { getPassengersFromQuery } from "../lib/passengers";
import Logger from "../services/Logger";
import golApiCall from "./golApiCall";
import { FlightSearch } from "./types/flightSearch";

function getGolRequestFlightSearch(
  query: SearchQuery,
  cbtPassengersForm,
  allowedTravelerTypes,
  travelers
) {
  const passengers = getGolRequestFlightSearchPassengers(
    query,
    cbtPassengersForm,
    allowedTravelerTypes,
    travelers
  );

  const flightSteps = getGolRequestFlightSteps(query);
  const payload = { passengers, flightSteps, flightClass: query.flightClass };
  return getGolRequestFlightSearchObject(payload, query);
}

function getGolRequestFlightSearchPassengers(
  query: SearchQuery,
  cbtPassengersForm,
  allowedTravelerTypes,
  travelers
): SearchedPassenger[] {
  const searchedPassengersForPassengerTypes = [];

  const isNotCbt =
    isFalsy(cbtPassengersForm) || cbtPassengersForm?.costCenter === null;
  const isHotels = query.typeSearch === "hotels";

  const extractTravelersFromQueryCbt = (allowedTravelerType) => {
    Object.keys(query)
      .filter((key) => key.endsWith(`_${allowedTravelerType.Code}`))
      .forEach((key) => {
        Array.from({ length: Number(query[key]) }).forEach(() =>
          searchedPassengersForPassengerTypes.push({
            Code: allowedTravelerType.Code,
          })
        );
      });
  };

  const extractTravelersFromQueryAnonymous = (allowedTravelerType) => {
    Array.from({ length: Number(query[allowedTravelerType.Code]) }).forEach(
      () =>
        searchedPassengersForPassengerTypes.push({
          Code: allowedTravelerType.Code,
        })
    );
  };

  allowedTravelerTypes.forEach((allowedTravelerType) => {
    if (isHotels && !isNotCbt) {
      extractTravelersFromQueryCbt(allowedTravelerType);
    } else if (!isNotCbt) {
      extractTravelersFromQueryCbt(allowedTravelerType);
    } else {
      extractTravelersFromQueryAnonymous(allowedTravelerType);
    }
  });

  const passengersCbt =
    Object.keys(travelers).length > 0
      ? cbtPassengersForm?.travellers?.map((id) => ({
          Code: travelers[id]?.PassengerTypeCode ?? "ADT",
        }))
      : [];

  return [...passengersCbt, ...searchedPassengersForPassengerTypes];
}

function getGolRequestFlightSteps(query: SearchQuery): FlightStep[] {
  const flexDays = query.toleranceDays && {
    FlexDays: { Before: query.toleranceDays, After: query.toleranceDays },
  };
  const departureDateTime = (q) =>
    Array.isArray(q.departureDate)
      ? q.departureDate[0].split("/").join("")
      : q.departureDate.split("/").join("");

  if (query.returnDate) {
    return [
      {
        Origin: Array.isArray(query.from) ? query.from[0] : query.from,
        Destination: Array.isArray(query.to) ? query.to[0] : query.to,
        DepartureDateTime: departureDateTime(query),
        ...flexDays,
      },
      {
        Origin: Array.isArray(query.to) ? query.to[0] : query.to,
        Destination: Array.isArray(query.from) ? query.from[0] : query.from,
        DepartureDateTime: Array.isArray(query.returnDate)
          ? query.returnDate[0].split("/").join("")
          : query.returnDate.split("/").join(""),
        ...flexDays,
      },
    ];
  }
  if (query.flight_1_departureDate) {
    return multiQueryToFlightSteps(query).map((oStep) => ({
      Origin: oStep.origin,
      Destination: oStep.destination,
      DepartureDateTime: oStep.departureDate,
      ...flexDays,
    }));
  }
  return [
    {
      Origin: Array.isArray(query.from) ? query.from[0] : query.from,
      Destination: Array.isArray(query.to) ? query.to[0] : query.to,
      DepartureDateTime: departureDateTime(query),
      ...flexDays,
    },
  ];
}

function getGolRequestFlightSearchObject(
  payload,
  query: SearchQuery
): SearchFlightsRequestExt {
  const { flightSteps, passengers, flightClass } = payload;

  const FlightClass =
    !flightClass || flightClass === "ECO"
      ? {}
      : { FlightClass: { Type: flightClass } };

  const request = {
    FlightSteps: {
      FlightStep: flightSteps,
    },
    SearchedPassengers: {
      SearchedPassenger: passengers,
    },
    FlightPreferences: {
      ...FlightClass,
    },
  };

  if (query.preferred_airline && query.preferred_airline !== "all") {
    request.FlightPreferences.PreferredCompany = {
      Type: query.preferred_airline,
    };
  }

  if (query.max_transfers && query.max_transfers === "direct") {
    request.FlightPreferences.DirectFlight = {};
  }

  request.FlightPreferences.IncludeCombinedFlights = {};

  return {
    GolApi: {
      RequestDetail: {
        SearchFlightsExtendedRequest_2: request,
      },
    },
  };
}

export function getFlightCombinationsTree(flightCombinations) {
  const combinationTree = {};
  flightCombinations.forEach((fc) => {
    const uniformBrandName = fc.UniformBrandName;
    combinationTree[uniformBrandName] = combinationTree[uniformBrandName] || [];
    combinationTree[uniformBrandName].push(
      fc.FlightOptionCombinations.FlightOptionCombination
    );
  });

  return combinationTree;
}

function getFlightsDataFromFlightSearchResponse(
  flightSearchResponse: ExtendedResponse2,
  cbtResolve
): FlightSearch {
  const flightOffers = [
    ...flightSearchResponse.ResponseDetail.SearchFlightsExtendedResponse_2
      .FlightOffers[0].FlightOffer,
  ];

  let maxNumTransfers = 0;

  const airportsFrom = [];
  const airportsTo = [];
  const airportsTransfers = [];
  const oFlightOffers = {};
  const aFlightOffers = [];

  const travelPoliciesResults =
    cbtResolve &&
    cbtResolve.TravelPoliciesResults.reduce((acc, user) => {
      acc[user.Value[0].Key] = user;
      return acc;
    }, {});
  const travelPolicies =
    cbtResolve &&
    cbtResolve.TravelPolicies.reduce((acc, pol) => {
      acc[pol.Id] = pol;
      return acc;
    }, {});

  const pricingDetailsByKey = flightOffers.reduce((acc, fo) => {
    fo.PricingDetails.PricingDetail.forEach((pd) => {
      acc[pd.Key] = pd;
    });
    return acc;
  }, {});

  let alternativeCurrencyPricingDetailsByKey;
  let alternativeCurrencyPrices;
  let lowestAlternativeCurrencyPrice = 0;
  let highestAlternativeCurrencyPrice = 999999;

  if (
    flightOffers.length > 0 &&
    flightOffers[0].AlternativeCurrencyPricingDetails
  ) {
    alternativeCurrencyPricingDetailsByKey = flightOffers.reduce((acc, fo) => {
      fo.AlternativeCurrencyPricingDetails.AlternativeCurrencyPricingDetail.forEach(
        (pd) => {
          acc[pd.Key] = pd;
        }
      );
      return acc;
    }, {});

    alternativeCurrencyPrices = Object.values(
      alternativeCurrencyPricingDetailsByKey
    ).map((pd) => Number(pd.FlightPricing.FlightPrice.FullPrice));
    lowestAlternativeCurrencyPrice = Math.min(...alternativeCurrencyPrices);
    highestAlternativeCurrencyPrice = Math.max(...alternativeCurrencyPrices);
  }

  const prices = Object.values(pricingDetailsByKey).map((pd) =>
    Number(pd.FlightPricing.FlightPrice.FullPrice)
  );

  const lowestPrice = Math.min(...prices);
  const highestPrice = Math.max(...prices);
  const newFlightOffers = flightOffers.map((offer) => {
    const offerKey = Math.random();
    const oOffer = {
      ...offer,
      numTransfers: 0,
      transportCompanies: [],
      randomKey: offerKey,
      key: offerKey,
    };

    const flightCombinationsTree = getFlightCombinationsTree(
      oOffer.FlightCombinations.FlightCombination
    );

    oOffer.FlightItinerary.FlightStream = oOffer.FlightItinerary.FlightStream.map(
      (stream, indexStream) => {
        return stream.FlightOption.map((oOption, optionIndex) => {
          const flightSegments = oOption.FlightSegments.FlightSegment;
          airportsFrom[indexStream] = safeUpdate(
            airportsFrom[indexStream],
            (a) => a.add(flightSegments[0].OriginAirport),
            new Set()
          );
          airportsTo[indexStream] = safeUpdate(
            airportsTo[indexStream],
            (a) =>
              a.add(
                flightSegments[flightSegments.length - 1].DestinationAirport
              ),
            new Set()
          );

          if (flightSegments.length > 1) {
            flightSegments.forEach((oSegment) => {
              airportsTransfers[indexStream] =
                airportsTransfers[indexStream] || new Set();

              const iteratorFrom = Array.from(airportsFrom[0])[0] || "";
              const iteratorTo = Array.from(airportsTo[0])[0] || "";

              if (iteratorFrom !== oSegment.OriginAirport) {
                airportsTransfers[indexStream].add(oSegment.OriginAirport);
              }
              if (iteratorTo !== oSegment.DestinationAirport) {
                airportsTransfers[indexStream].add(oSegment.DestinationAirport);
              }
            });
          }

          if (Array.isArray(oOption.FlightSegments.FlightSegment)) {
            if (
              maxNumTransfers <
              oOption.FlightSegments.FlightSegment.length - 1
            ) {
              maxNumTransfers = oOption.FlightSegments.FlightSegment.length - 1;
            }
          }

          const numTransfersInOption =
            oOption.FlightSegments.FlightSegment.length - 1;

          if (optionIndex === 0) {
            oOffer.numTransfers += numTransfersInOption;
          }

          flightSegments.forEach((oSegment) => {
            if (
              !oOffer.transportCompanies.includes(oSegment.MarketingAirline)
            ) {
              oOffer.transportCompanies.push(oSegment.MarketingAirline);
            }
          });
          return { ...oOption, numTransfers: numTransfersInOption };
        });
      }
    );

    oFlightOffers[offerKey] = oOffer;
    aFlightOffers.push(offerKey);

    oOffer.FlightCombinations = oOffer.FlightCombinations.FlightCombination.map(
      (fc) => {
        const alternativeCurrencyOption = alternativeCurrencyPricingDetailsByKey
          ? {
              AlternativeCurrencyPricingDetail:
                alternativeCurrencyPricingDetailsByKey[fc.PricingDetailKey],
            }
          : {};
        return {
          ...fc,
          PricingDetail: pricingDetailsByKey[fc.PricingDetailKey],
          ...alternativeCurrencyOption,
        };
      }
    );
    oOffer.CurrentCombinations = flightCombinationsTree;

    return oOffer;
  });

  const airports = parseAirport(flightSearchResponse.CodeBook);
  const airplanes = parseAirplanes(flightSearchResponse.CodeBook);
  const transportCompanies = parseTransportCompanies(
    flightSearchResponse.CodeBook
  );
  return {
    maxNumTransfers,
    airportsFrom: airportsFrom.map((a) => Array.from(a)),
    airportsTo: airportsTo.map((a) => Array.from(a)),
    airportsTransfers: airportsTransfers.map((a) => Array.from(a)),
    lowestPrice,
    highestPrice,
    lowestAlternativeCurrencyPrice,
    highestAlternativeCurrencyPrice,
    airports,
    oTransportCompanies: transportCompanies,
    airplanes,
    flights: newFlightOffers,
    mobileOffersSortedFiltered: newFlightOffers,
    oFlightOffers,
    aFlightOffers,
    travelPoliciesResults,
    travelPolicies,
    isError: false,
    currency:
      flightSearchResponse.Settings.Currency !== undefined
        ? flightSearchResponse.Settings.Currency.Code
        : "",
  };
}

function multiQueryToFlightSteps(query: SearchQuery) {
  // deprecated?
  return Object.entries(query).reduce((acc, [attr, val]) => {
    if (attr.includes("flight_")) {
      const [, idx1, newAttr] = attr.split("_");
      const i = Number(idx1) - 1;
      acc[i] = { ...acc[i], [newAttr]: val };
      return acc;
    }
    return acc;
  }, []);
}

function prepareCbtParams({
  query,
  searchFlightsExtendedResp,
  cbtPassengersForm,
  cbtToken,
  customerUsername,
  cbtApiUrl,
}) {
  if (!cbtToken) return {};

  const tripType = getTripType(query);

  return {
    cbtToken,
    userIds: cbtPassengersForm.travellers,
    costCenterId: cbtPassengersForm.costCenter,
    travelReasonId: cbtPassengersForm.travelReason,
    guestsGradeIds: cbtPassengersForm.guestsGradeIds,
    tripType,
    searchFlightsExtendedResp,
    customerUsername,
    cbtApiUrl,
  };
}

export default async function flightSearch(
  query: SearchQuery,
  {
    cbtToken,
    cbtPassengersForm,
    customerUsername,
    cbtApiUrl,
    allowedTravelerTypes,
    travelers,
  }: {
    cbtToken?: string;
    cbtPassengersForm: CbtPassengersForm;
    customerUsername?: string;
    cbtApiUrl?: string;
    allowedTravelerTypes?: any[];
    travelers?: any[];
  }
): Promise<FlightSearch> {
  try {
    const golRequest = getGolRequestFlightSearch(
      query,
      cbtPassengersForm,
      allowedTravelerTypes,
      travelers
    );

    const searchFlightsExtendedResp = await golApiCall(golRequest, {
      auth: false,
      ...(query.currency ? { alternativeCurrency: query.currency } : {}),
    });

    const resp = createResponse(searchFlightsExtendedResp);

    if (!resp.success) {
      Logger.error(resp);
      return {
        isError: true,
        errorMsg: resp.errorMsg,
        errorCode: resp.errorCode,
      };
    }
    let cbtResolve;
    if (resp.data.FlightOffers[0].FlightOffer.length > 0) {
      const preparedCBTParams = prepareCbtParams({
        query,
        searchFlightsExtendedResp,
        cbtPassengersForm,
        cbtToken,
        customerUsername,
        cbtApiUrl,
      });

      const passengers = getPassengersFromQuery(query, allowedTravelerTypes);

      const totalGuestsFromPassengers = Object.values(passengers).reduce(
        (sum: number, count: number) => sum + count,
        0
      );

      cbtResolve = await resolvePoliciesGOL({
        guests: totalGuestsFromPassengers,
        ...preparedCBTParams,
      });
    }

    return getFlightsDataFromFlightSearchResponse(
      searchFlightsExtendedResp,
      cbtResolve && cbtResolve.success ? cbtResolve.data : null
    );
  } catch (e) {
    Logger.error(e);
    return {
      isError: true,
    };
  }
}
